import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import PrCard from "@/design-system/PrCard";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const StyleCircle = styled(Box)`
  padding: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PrCardComponent = ({ data }: any) => {
  return (
    <Link className="link__" href={data?.link} target="_blank">
      <PrCard>
        <Box>
          <Typography
            fontFamily={"DM Serif Text !important"}
            varient="heading5"
            weightVarient="semibold"
          >
            “
          </Typography>
          <Typography
            fontFamily={"DM Serif Text !important"}
            varient="heading5"
            weightVarient="semibold"
          >
            {data?.description}
          </Typography>
        </Box>
        <FlexContainer
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={{ _: "8px", md: "40px" }}
        >
          <Box>
            <ExportedImage
              width={88}
              height={32}
              src={data?.logo}
              alt=""
              style={{ height: "auto" }}
            />
          </Box>
          <StyleCircle className="circle">
            <Icon type={"arrow-narrow-up-right"} color={theme.colors.gray[400]} />
          </StyleCircle>
        </FlexContainer>
      </PrCard>
    </Link>
  );
};

export default PrCardComponent;
