import { motion } from "framer-motion";
import { ReactNode } from "react";

const FadeInWhenVisible = ({
  children,
  style,
  duration = 0.3,
  zIndex,
  variants,
  viewport,
}: {
  children: ReactNode;
  style?: React.CSSProperties;
  zIndex?: number | string;
  variants?: any;
  viewport?: any;
  duration?: number;
}) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      style={{
        originX: 0.75,
        originY: 0.5,
        ...style,
        zIndex: zIndex ? zIndex : "unset",
      }}
      transition={{ ease: "easeInOut", duration: duration }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0.9, scale: 0.95 },
        ...variants,
      }}
      viewport={{ amount: 0.4, ...viewport }}
    >
      {children}
    </motion.div>
  );
};

export default FadeInWhenVisible;
