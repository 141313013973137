import Lottie from "lottie-react";
import { useRouter } from "next/router";
import ExportedImage from "next-image-export-optimizer";
import React, { useContext } from "react";
import styled from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";

import { useAppSelector } from "@/hooks/hooks";
import useScreenDuration from "@/hooks/useScreenDuration";

import { PreviousRouteProvider } from "@/pages/_app";

import { getScrollPositionByPercentage } from "@/utils/utils";

import { theme } from "@/theme";

const WhatsAppButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 12px;
  position: fixed;
  right: 24px;
  bottom: 25px;
  width: fit-content;
  background: #e6ffe9;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  border: 1px solid #86cb3c;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const WhatsappWidgetMobile = () => {
  const mobile = 919319745217;
  const router = useRouter();

  const loginDetails = useAppSelector((state) => state.rootReducer.auth);
  const { previousRoute } = useContext(PreviousRouteProvider);
  const screenTime = useScreenDuration();

  const handleLinkClick = (e) => {
    const { userId, userProfile, userData, isAuthenticated } = loginDetails;
    const linkClickTrackObj = {
      link_text: e.currentTarget.innerText,
      user_id: userId,
      name: userProfile.firstName,
      email: loginDetails.userData.emailId,
      Page_path: window.location.pathname,
      Page_Referrer: previousRoute,
      percent_scrolled: getScrollPositionByPercentage(),
      on_screen_duration: screenTime.formattedTime,
      loginDetails: userData,
      isLoggedIn: isAuthenticated,
      Current_Page: window.location.href,
    };
    trackleadform("Link_Click", linkClickTrackObj);
  };
  return (
    <>
      <a
        onClick={handleLinkClick}
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/${mobile}?text=https://www.novatr.com${router.asPath}%0A%0AHey%2C%0AI%20read%20about%20your%20program%20on%20Novatr.com%20and%20had%20a%20few%20questions%20about%20it.%20Can%20you%20help%20me%3F`}
      >
        <WhatsAppButton className="chat_with_us" zIndex={297893789}>
          <ExportedImage
            src={"/images/home/logos_whatsapp-icon.png"}
            width={40}
            height={40}
            alt="whatsapp-icon"
          />
        </WhatsAppButton>
      </a>
    </>
  );
};

export default WhatsappWidgetMobile;
