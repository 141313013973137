import styled from "styled-components";

import UnstyledButton from "@/design-system/Button/UnstyledButton";

import { theme } from "@/theme";

export const ArrowButtonStyle = styled(UnstyledButton)`
  width: 52px;
  height: 52px;
  align-items: center;
  justify-content: center;
  border-radius: ${({ br }: { br?: string }) => br || "8px"};
  border: 1px solid ${theme.colors.gray[300]};
`;
