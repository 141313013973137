import styled from "styled-components";

import { theme } from "@/theme";

export const StyledPrCard = styled.div`
  background: ${theme.colors.primary[50]};
  border-radius: 14px;
  padding: 24px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: white;
    border: 2px solid ${theme.colors.primary[200]};
    box-shadow: ${theme.shadows.primary.sm};
  }
  &:hover .circle {
    background: ${theme.colors.primary[50]};
  }
  &:hover svg path {
    stroke: ${theme.colors.primary[800]};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 40px;
    min-height: 100%;
  }
`;
