import { FC } from "react";

import { StyledPrCard } from "./prCard.styles";
import { PrCardProps } from "./types";

const PrCard: FC<PrCardProps> = ({ children, className }) => {
  return <StyledPrCard className={className}>{children}</StyledPrCard>;
};

export default PrCard;
