import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Container from "@/design-system/Container";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import ReadMoreCenter from "@/design-system/ReadMore/ReadMoreCenter";
import useMediaQuery from "@/design-system/useMediaQuery";
import { ArrowButtonStyle } from "@/design-system/Utility/ArrowButton";

import { theme } from "@/theme";

import "keen-slider/keen-slider.min.css";

import PrCardComponent from "./PrCardComponent";

const data = [
  {
    description:
      "Oneistox announces $1.2 million in seed funding; Y-Combinator, Powerhouse Ventures, Soma Capital leads",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/financial_express.png`,
    link: "https://www.financialexpress.com/education-2/oneistox-announces-1-2-million-in-seed-funding-y-combinator-powerhouse-ventures-soma-capital-leads/3043724/",
  },
  {
    description: "Oneistox Secures Funding From YC, Powerhouse To Upskill AEC Professionals",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/inc42.svg`,
    link: "https://inc42.com/buzz/upskilling-edtech-startup-oneistox-secures-funding-to-make-professionals-future-ready/",
  },
  {
    description: "EDTECH START-UP ONEISTOX RAISES $1.2 MILLION SEED FUNDING",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/outlook_startup.png`,
    link: "https://startup.outlookindia.com/sector/ed-tech/edtech-start-up-oneistox-raises-1-2-million-seed-funding-news-8069",
  },
  {
    description: "Edtech startup Oneistox raises $1.2 Mn seed money",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/entrackr.png`,
    link: "https://entrackr.com/2023/04/edtech-startup-oneistox-raises-1-2-mn-seed-money/",
  },
  {
    description:
      "AEC Ed-Tech Startup Oneistox Announces $1.2 Million In Seed Funding From Y-Combinator, Powerhouse Ventures, Soma Capital And Notable Angels",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/apn_news.png`,
    link: "https://www.apnnews.com/aec-ed-tech-startup-oneistox-announces-1-2-million-in-seed-funding-from-y-combinator-powerhouse-ventures-soma-capital-and-notable-angels/",
  },
  {
    description:
      "Oneistox raises $1.2mn in seed funding from Y Combinator, Powerhouse Ventures, Soma Capital, others",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/media_brief.png`,
    link: "https://mediabrief.com/oneistox-raises-1-2mn-in-seed-funding/",
  },
  {
    description: "Upskilling Startup Oneistox Raises $1.2M in Seed Round Led by Y Combinator",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/edtechreview.png`,
    link: "https://www.edtechreview.in/news/upskilling-startup-oneistox-raises-1-2m-in-seed-round-led-by-y-combinator/",
  },
  {
    description:
      "AEC ed-tech Startup OneistoX secures $1.2 million in seed funding from Y-Combinator, Powerhouse Ventures, Soma Capital and notable angels",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/logosmall.png`,
    link: "https://www.passionateinmarketing.com/aec-ed-tech-startup-oneistox-secures-1-2-million-in-seed-funding-from-y-combinator-powerhouse-ventures-soma-capital-and-notable-angels/",
  },
  {
    description:
      "STARTUP NEWS AND UPDATES: DAILY ROUNDUP (APRIL 12, 2023) Read more at: https://yourstory.com/2023/04/startup-news-and-updates-daily-roundup-april-12-2023",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/yourstory.png`,
    link: "https://yourstory.com/2023/04/startup-news-and-updates-daily-roundup-april-12-2023",
  },
  {
    description:
      "Funding Rundown: PhonePe gets additional $100 mil from General Atlantic, pi Ventures raises Rs 100 crore & more",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/CNBC_tv18.png`,
    link: "https://www.cnbctv18.com/startup/funding-rundown-phonepe-gets-additional-100-mil-from-general-atlantic-pi-ventures-raises-rs-100-crore--more-16392451.htm",
  },
  {
    description:
      "SimpliContract Raises Pre-Series A Round; Hardik Pandya Invests In Instant Food Brand",
    logo: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/logos/vccircle.png`,
    link: "https://www.vccircle.com/simplicontractraises-pre-series-a-round-hardik-pandya-invests-in-instant-food-brand",
  },
];

const PRSection = () => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    mode: "free-snap",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      perView: 3,
      spacing: 24,
    },
  });

  const backClick = (e) => {
    return e.stopPropagation() || instanceRef.current?.prev();
  };
  const nextClick = (e) => {
    return e.stopPropagation() || instanceRef.current?.next();
  };
  return (
    <Box overflowY={"hidden"} mt={{ _: "16px", md: "40px" }}>
      <Container pb={"40px"}>
        {isMobile ? (
          <>
            {data.slice(0, 3).map((val, key) => {
              return (
                <Box mt={theme.spacing(6)} key={key}>
                  <PrCardComponent data={val} />
                </Box>
              );
            })}

            <ReadMoreCenter>
              {data.slice(3, data.length).map((val, key) => {
                return (
                  <Box mt={theme.spacing(6)} key={key}>
                    <PrCardComponent data={val} key={key} />
                  </Box>
                );
              })}
            </ReadMoreCenter>
          </>
        ) : (
          <>
            <Box display={{ _: "none", md: "block" }}>
              <Box ref={sliderRef} className="keen-slider">
                {data.map((val, key) => {
                  return (
                    <Box className={"keen-slider__slide"} key={key} paddingBottom={"20px"}>
                      <PrCardComponent data={val} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
            {loaded && instanceRef?.current && (
              <FlexContainer
                px={theme.spacing(10)}
                justifyContent={"space-between"}
                alignItems={"center"}
                mt={"16px"}
              >
                <Dots currentSlide={currentSlide} instanceRef={instanceRef} />
                <Box>
                  <ArrowButtonStyle
                    mr={theme.spacing(2)}
                    onClick={backClick}
                    disabled={currentSlide === 0}
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <Icon type="arrow-right" size={20} varient="solid" />
                  </ArrowButtonStyle>

                  <ArrowButtonStyle
                    onClick={nextClick}
                    disabled={currentSlide === instanceRef.current.track.details.slides.length - 3}
                  >
                    <Icon type="arrow-right" size={20} varient="solid" />
                  </ArrowButtonStyle>
                </Box>
              </FlexContainer>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default PRSection;

const DotStyle = styled(UnstyledButton)<{ active: boolean }>`
  width: ${({ active }) => (active ? "43px" : "4px")};
  height: 4px;
  background-color: #000;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  margin-right: ${theme.spacing(2)};
  border-radius: 10px;
  transition: 0.3s;
`;

const Dots = ({ instanceRef, currentSlide }: any) => {
  return (
    <FlexContainer>
      {[...(Array(instanceRef.current.track.details?.slides.length - 2).keys() as any)].map(
        (idx) => {
          return (
            <DotStyle
              active={currentSlide === idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              key={idx}
            />
          );
        }
      )}
    </FlexContainer>
  );
};
