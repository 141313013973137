import styled from "styled-components";
import { space } from "styled-system";

export const StyledDivider = styled.hr<any>`
  ${space};
  border-style: solid;
  border-color: ${({ color }) => color || "black"};
  border-width: 0;
  border-bottom-width: ${({ height }) => height || "1px"};
`;
